import React, { useState, useEffect, useMemo } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import lodash from 'lodash';
import { useNavigate } from 'react-router-dom';

import Styles from './one21-event.module.scss'
import 'react-toastify/dist/ReactToastify.css'

//Assets
import { ReactComponent as ArrowLeft } from 'assets/arrow-left-tail.svg'
import { ReactComponent as ArrowRight } from 'assets/arrow-right.svg'
import { ReactComponent as RadioCheck } from 'assets/radio-btn-check.svg'
import { ReactComponent as CheckboxSelected } from 'assets/checkbox-selected.svg'
import { ReactComponent as CheckboxUnselected } from 'assets/checkbox-unselect.svg'

import One21Event from 'assets/one21-event.jpeg'
import One21StageLayout from 'assets/stage-layout.jpg'

// API'S
import { getIpafEvents, getMarketingAgent, submitRegistration } from "actions"

// Components
import { ModalLoader } from 'components/organism'

const useIpafEvent = () => {
  const [jenisEvent, setJenisEvent] = useState([])
  const [isFetchJenisEvent, setIsFetchJenisEvent] = useState(true)
  const [selectedJenisEvent, setSelectedJenisEvent] = useState(null)

  const [marketingAgent, setMarketingAgent] = useState([])
  const [isFetchMarketingAgent, setIsFetchMarketingAgent] = useState(true)
  const [selectedMarketingAgent, setSelectedMarketingAgent] = useState([])

  const [isSubmitRegistration, setIsSubmitRegistration] = useState(false)

  const isLoading = useMemo(() => {
    return isFetchJenisEvent || isFetchMarketingAgent || isSubmitRegistration
  }, [isFetchJenisEvent, isFetchMarketingAgent, isSubmitRegistration])

  const handleSubmitRegistration = async () => {
    try {
      if (!selectedJenisEvent) throw new Error("Pilih jenis event terlebih dahulu");
      if (!selectedMarketingAgent.length) throw new Error("Pilih marketing agent terlebih dahulu");

      setIsSubmitRegistration(true)
      const payload = {
        ipaf_event_booking: {
          ipaf_event_id: selectedJenisEvent.id,
          user_ids: selectedMarketingAgent.map(item => item.id)
        }
      }
      const response = await submitRegistration(payload)
      const invoice_url = lodash.get(response, "data.webhook.payment_outgoing.invoice_url")
      window.open(invoice_url, "_self")
    } catch (error) {
      if (typeof error === "string") return alert(error)
      if (typeof error === "object") return alert(error.message)
    } finally {
      setIsSubmitRegistration(false)
    }
  }

  const handleSelectMarketingAgent = (id) => {
    if (selectedMarketingAgent.includes(id)) {
      setSelectedMarketingAgent(val => val.filter(item => item !== id))
    } else {
      setSelectedMarketingAgent(val => [...val, id])
    }
  }

  const handleGetJenisEvent = async () => {
    try {
      setIsFetchJenisEvent(true)
      const res = await getIpafEvents()
      setJenisEvent(res.data || [])
    } catch (err) {
      console.log(err)
    } finally {
      setIsFetchJenisEvent(false)
    }
  }

  const handleGetMarketingAgent = async (page = 1) => {
    try {
      setIsFetchMarketingAgent(true)
      const res = await getMarketingAgent(`?page=${page}&_limit=50&per_page=50`)
      if (res.data.length) {
        setMarketingAgent(val => [...val, ...res.data])
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsFetchMarketingAgent(false)
    }
  }

  useEffect(() => {
    handleGetJenisEvent()
    handleGetMarketingAgent()
  }, [])

  return {
    isLoading,
    jenisEvent,
    selectedJenisEvent,
    marketingAgent,
    selectedMarketingAgent,
    handleSelectMarketingAgent,
    handleSubmitRegistration,
    setSelectedJenisEvent
  }
}

const IpafEvent = () => {
  const navigate = useNavigate()
  const { isLoading, jenisEvent, selectedJenisEvent, marketingAgent, handleSubmitRegistration, setSelectedJenisEvent, selectedMarketingAgent, handleSelectMarketingAgent } = useIpafEvent()

  return (
    <div className={`${Styles['wrapper']} nav-content-wrapper`}>
      <div className={Styles['header']}>
        <p className={Styles['title']}>Registrasi Event</p>
        <button onClick={() => navigate("/")}>
          <ArrowLeft />
          <span>Kembali ke halaman utama</span>
        </button>
      </div>

      <div className={Styles['body']}>
        <div className={Styles['content']}>
          <div className={Styles['banner']}>
            <img src={One21Event} alt="ipaf-banner" />
          </div>
          {/* <div className={`${Styles['banner']} !h-[1000px]`}>
            <img src={One21StageLayout} alt="ipaf-banner" />
          </div> */}

          <div className={Styles['jenis-event']}>
            <p className={Styles['title']}>Jenis Tiket</p>
            <p className={Styles['sub-title']}>Silakan pilih tiket yang hendak anda pesan.</p>

            <div className={Styles['list-jenis-event']}>
              {!!jenisEvent.length && jenisEvent.map((item, index) => (
                item.is_active && <div className={Styles['item']} key={index} onClick={() => setSelectedJenisEvent(item)}>
                  {!!selectedJenisEvent && selectedJenisEvent.id === item.id ? <RadioCheck /> : <RadioCheck className={Styles['uncheck']} />}

                  <span>{item.category}</span>
                </div>
              ))}
            </div>

          </div>

          <div className={Styles['informasi-event']}>
            <div className={Styles['informasi-event']}>
              <p className={Styles['title']}>Informasi Event</p>
              <p className={Styles['sub-title']}>
                Tanggal Acara : 25 Februari 2025 <br />
                <div className='my-1' />
                Lokasi: Raffles Hotel Jakarta <br />
                <div className='my-1' />
                ⁠Harga : 799.000/pax <br />
                <div className='my-4' />
                ⁠Promo : <br />
                <div className='my-1' />
                Min. 10 pax: pembayaran cicilan 3x bayar (DP 40% / 30% di Januari / 30% di Februari)
              </p>
            </div>
          </div>

          <div className={Styles['pilih-marketing']}>
            <p className={Styles['title']}>Pilih Marketing</p>
            <p className={Styles['sub-title']}>Silakan pilih marketing yang akan didaftarkan pada acara ONE21 2024:</p>
            <p className={Styles['marketing-terpilih']}>Marketing yang diikutsertakan: <span>{selectedMarketingAgent.length} orang</span></p>

            <div className={Styles['list-marketing']}>
              {!!marketingAgent.length && marketingAgent.map((item, index) => (
                <div className={Styles['item']} key={index} onClick={() => handleSelectMarketingAgent(item)}>
                  {!!selectedMarketingAgent.find(val => val.id == item.id) ? <CheckboxSelected /> : <CheckboxUnselected />}
                  <span>{item.full_name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={Styles['footer']}>

        <button disabled={isLoading} className={Styles['btn']} onClick={handleSubmitRegistration}>
          <span>Submit Data</span>
          <ArrowRight />
        </button>

      </div>
      <ModalLoader open={isLoading} />
      <ToastContainer />
    </div>
  )
}

export default IpafEvent