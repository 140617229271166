import { useContext, useMemo, useState, useEffect  } from 'react'
import lodash from "lodash"
import dayjs from 'dayjs';

// Assets
import { ReactComponent as InfoGreyIcon } from "assets/info-1.svg";
import { ReactComponent as IconInfo } from "assets/info-black-2.svg";
// Context
import { useTTCtx } from "pages/dashboard/e-reporting/TableTransaksi/TTCtx";
import { GlobalContext } from 'contexts';

// Components
import ModalConfirmation from "./_components/ModalConfirmation";
import ModalAddInformation from "./_components/ModalAddInformation";
import ModalInformation from "./_components/ModalInformation";
import ModalUmurTrx from "./_components/ModalUmurTrx";
import ModalCancelCommision from "./_components/ModalCancelCommision";
import ModalSuccessCancelCommision from "./_components/ModalSuccessCancelCommision";
import BodyCollumn from "./_components/BodyCollumn";

// Actions
import { submitCommision, getCommisionHistoryTx } from "actions"
import toRupiah from 'helpers/toRupiah';

const DISPLAYED_MODAL = {
  information: "information",
  addInformation: "add-information",
  confirmation: "confirmation",
  umurTrx: "umur-trx",
  cancelRemainingCommision: "cancel-remaining-comission",
  successCancelRemainingCommision: "success-cancel-remaining-comission",
}

const MainWidthClass = {
  tanggalDisetujui: "w-[9.7%] mr-4",
  marketing: "w-[34.74%] mr-4",
  totalNilaiTrx: "w-[13%] mr-4",
  tipe: "w-[5.21%] mr-4",
  trxId: "w-[5.21%] mr-4",
  status: "w-[16.37%]"
}

const LabelHeader = ({ label, className, ...props }) => (<p className={`text-[11px] text-[#808285] font-inter ${className}`} {...props}>{label}</p>)
const LabelHeaderInfo = ({ label, className, ...props }) => (<p className={`text-[11px] text-[#808285] font-inter flex items-center ${className}`} {...props}>{label} <IconInfo className='ml-0.5 w-4 h-4 object-contain' /></p>)

const TableHeader = () => {
  return (
    <div className='px-[32px] py-[16px] flex items-center border-b-[0.5px] border-solid border-[#D8D8D9]'>
      <div className={`${MainWidthClass.tanggalDisetujui}`}>
        <LabelHeader label="Tanggal Disetujui" />
      </div>
      <div className={`${MainWidthClass.marketing}`}>
        <LabelHeader label="Marketing" />
      </div>
      <div className={`${MainWidthClass.totalNilaiTrx}`}>
        <LabelHeader label="Total Nilai Trx" />
      </div>
      <div className={`${MainWidthClass.tipe}`}>
        <LabelHeader label="Tipe" />
      </div>
      <div className={`${MainWidthClass.trxId}`}>
        <LabelHeader label="Trx ID" />
      </div>
      <div className={`${MainWidthClass.status}`}>
        <LabelHeaderInfo label="Umur Transaksi" />
      </div>
    </div>
  )
}

const TableBelumLunas = () => {
  const { transactionList, getTransactionReport, transactionPage } = useTTCtx();
  const { setShowLoading } = useContext(GlobalContext)

  const [isOpenModalInformation, setIsOpenModalInformation] = useState(false)
  const [isOpenModalAddInfo, setIsOpenModalAddInfo] = useState(false)
  const [isOpenModalConfirmation, setIsOpenModalConfirmation] = useState(false)
  const [isOpenUmurTrx, setIsOpenUmurTrx] = useState(false)
  const [isOpenCancelCommision, setIsOpenCancelCommision] = useState(false)
  const [isOpenSuccessCancelCommision, setIsOpenSuccessCancelCommision] = useState(false)

  const [selectedTrx, setSelectedTrx] = useState(null)
  const idTrx = useMemo(() => selectedTrx?.id ?? null, [selectedTrx]);

  // Forms
  const [repportDate, setRepportDate] = useState(dayjs())
  const [receivedAmount, setReceivedAmount] = useState(0)

  // Files
  const [files, setFiles] = useState([])

  // Commision History
  const [commisionHistory, setCommisionHistory] = useState([])
  const [dataInfo, setDataInfo] = useState(null)

  const resetState = (trx) => {
    setSelectedTrx(trx ?? null)
    setRepportDate(dayjs())
    setReceivedAmount(0)
    setFiles([])
  }

  // Modal orchestrator
  const modalController = (type) => {
    setIsOpenModalInformation(type === DISPLAYED_MODAL.information)
    setIsOpenModalAddInfo(type === DISPLAYED_MODAL.addInformation)
    setIsOpenModalConfirmation(type === DISPLAYED_MODAL.confirmation)
    setIsOpenUmurTrx(type === DISPLAYED_MODAL.umurTrx)
    setIsOpenCancelCommision(type === DISPLAYED_MODAL.cancelRemainingCommision)
    setIsOpenSuccessCancelCommision(type === DISPLAYED_MODAL.successCancelRemainingCommision)
  }

  // Modal Toggler
  const handleOpenModalInformation = (trx) => {
    resetState(trx)
    modalController(DISPLAYED_MODAL.information)
  }
  const handleCloseModalInformation = () => {
    resetState()
    modalController(null)
  }
  const handleOpenModalAddInfo = () => modalController(DISPLAYED_MODAL.addInformation)
  const handleCloseModalAddInfo = () => modalController(DISPLAYED_MODAL.information)
  const handleOpenModalConfirmation = () => {
    if (!repportDate || !receivedAmount) return;

    modalController(DISPLAYED_MODAL.confirmation)
  }

  const handleOpenModalCancelCommistion = () => modalController(DISPLAYED_MODAL.cancelRemainingCommision)
  const handleCloseModalCancelCommistion = () => {
    resetState()
    modalController(null)
  }

  const handleCloseModalConfirmation = () => modalController(DISPLAYED_MODAL.addInformation)

  const handleOpenModalUmurTrx = () => modalController(DISPLAYED_MODAL.umurTrx)
  const handleCloseModalUmurTrx = () => {
    resetState()
    modalController(null)
  }

  const handleOpenModalSuccessCancelCommission = () => modalController(DISPLAYED_MODAL.successCancelRemainingCommision)
  const handleCloseModalSuccessCancelCommission = () => {
    resetState()
    modalController(null)
  }


  const handleAddTrx = async () => {
    try {
      setShowLoading(true)
      await submitCommision(selectedTrx?.id, { date: dayjs(repportDate).format("YYYY-MM-DD"), amount: lodash.toNumber(receivedAmount) })
      await getTransactionReport(transactionPage, {}, true)
      handleCloseModalInformation()
    } catch (error) {
      console.log(error)
    } finally {
      setShowLoading(false)
    }
  }

  useEffect(() => {
    async function handleGetCommisionHistoryTx() {
      try {
        setShowLoading(true)
        const result = await getCommisionHistoryTx(idTrx)
        const newData = result?.data?.data ?? {}
        const dataResult = {
          totalCommision: toRupiah(newData?.total_commission ?? 0),
          pendingCommision: toRupiah(newData?.unreceived_commission ?? 0),
          totalReceivedCommission: toRupiah(newData?.received_commission ?? 0)
        }
        setDataInfo(dataResult)
        setCommisionHistory(newData?.detail_payments ?? [])
      } catch (error) {
        console.log(error)
      } finally {
        setShowLoading(false)
      }
    }

    if (idTrx) handleGetCommisionHistoryTx()
  }, [idTrx])

  return (
    <>
      <div className=''>
        <div className='rounded-[4px] border-[0.5px] border-solid border-white-0 bg-white'>
          {/*Table Head*/}
          <TableHeader />

          {/*Table Body*/}
          {!!transactionList?.length && transactionList.map((datum, key) => <BodyCollumn openModal={() => handleOpenModalInformation(datum)} thisTx={datum} key={`body-column-${key}`} />)}
        </div>
      </div>
      <ModalInformation
        onNextModal={handleOpenModalAddInfo}
        onClose={handleCloseModalInformation}
        isOpen={isOpenModalInformation}
        trxId={idTrx}
        selectedTrx={selectedTrx}
        onOpenCancelCommision={handleOpenModalCancelCommistion}
        commisionHistory={commisionHistory}
        data={dataInfo}

      />
      <ModalAddInformation
        repportDate={repportDate}
        isOpen={isOpenModalAddInfo}
        receivedAmount={receivedAmount}
        setRepportDate={setRepportDate}
        onClose={handleCloseModalAddInfo}
        setReceivedAmount={setReceivedAmount}
        onNextModal={handleOpenModalConfirmation}
        idTrx={idTrx}
      />
      <ModalConfirmation
        onClose={handleCloseModalConfirmation}
        isOpen={isOpenModalConfirmation}
        onSubmit={handleAddTrx}
      />
      <ModalCancelCommision
        isOpen={isOpenCancelCommision}
        onClose={handleCloseModalCancelCommistion}
        files={files}
        setFiles={setFiles}
        onNextModal={handleOpenModalSuccessCancelCommission}
        idTrx={idTrx}
        pendingCommision={dataInfo?.pendingCommision ?? 0}
      />
      <ModalSuccessCancelCommision
        isOpen={isOpenSuccessCancelCommision}
        onClose={handleCloseModalSuccessCancelCommission}
      />
      <ModalUmurTrx
        isOpen={isOpenUmurTrx}
        onClose={handleCloseModalUmurTrx}
      />
    </>
  )
}

export default TableBelumLunas
