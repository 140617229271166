import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Styles
import Styles from './ipaf-popup-banner.module.scss'

// Assets
import One21Event from 'assets/one21-event.jpeg'
import CloseIcon from 'assets/close-icon-white.png'

const IpafPopupBanner = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [isShow, setIsShow] = useState(true)

  if (location.pathname === '/one21-event') return null
  if (!isShow) return null
  return (
    <div className={Styles['wrapper']} onClick={() => setIsShow(false)} >
      <div className={Styles['banner-wrapper']}>
        <img src={One21Event} alt="ipaf-banner" onClick={() => navigate("/one21-event")} />
        <img className={Styles["close-icon"]} src={CloseIcon} alt="close-icon" onClick={() => setIsShow(false)} />
      </div>
    </div>
  )
}

export default IpafPopupBanner