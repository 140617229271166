import React, { useContext } from 'react'
import { Outlet } from "react-router-dom";
import { Navbar } from "../../navigation";

import IpafPopupBanner from 'components/specific/ipaf-popup-banner';
import One21PopupBanner from 'components/specific/one21-popup-banner';
import { GlobalContext } from "contexts";
import GlobalLoader from 'components/organism/GlobalLoader';
import InfoApprovalTrx from "components/info-approval-trx"

const Dashboard = () => {
  const { showIpaf, showOne21Event } = useContext(GlobalContext);

  return (
    <div id="dashboard">
      <Navbar />
      <Outlet />
      <GlobalLoader />
      {showIpaf && <IpafPopupBanner />}
      {showOne21Event && <One21PopupBanner />}
      <InfoApprovalTrx />
    </div>

  )
}

export default Dashboard