import { useMemo, useState } from 'react'

//Styles
import Styles from './pilih-durasi-sewa.module.scss'

//Components
import TextMedium from 'pages/dashboard/e-reporting/forms/components/reuseables/text-medium'
import TextLarge from 'pages/dashboard/e-reporting/forms/components/reuseables/text-large'
import Order from 'pages/dashboard/e-reporting/forms/components/reuseables/order'
import WrapperAndBox from 'pages/dashboard/e-reporting/forms/components/reuseables/wrapper-and-box/WrapperAndBox'
import SelectWithSearch from 'pages/dashboard/e-reporting/forms/components/reuseables/select-with-search/SelectWithSearch'
import InputText from 'pages/dashboard/e-reporting/forms/components/reuseables/input-text'
import ButtonSelanjutnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-selanjutnya/ButtonSelanjutnya'
import ButtonSebelumnya from 'pages/dashboard/e-reporting/forms/components/reuseables/button-sebelumnya'
import BoxFlex from 'pages/dashboard/e-reporting/forms/components/reuseables/box-flex'

//Context
import { STEPS, useFormMethod, useFormState } from "pages/dashboard/e-reporting/forms/context";

function PilihDurasiSewa() {
  const options = useMemo(() => {
    return [
      {
        id: 1,
        name: "Tahun"
      },
      {
        id: 2,
        name: "Bulan"
      },
      // {
      //   id: 3,
      //   name: "Minggu"
      // },
      // {
      //   id: 4,
      //   name: "Hari"
      // },
    ]
  })
  const { setCurrentStep, setForm, handlePrevState } = useFormMethod();
  const { disabledAll, form } = useFormState()

  //State
  const [selectedData, setSelectedData] = useState(form.rent_period.value)
  const [value, setValue] = useState(form.rent_period_total.value)

  const mappedData = useMemo(() => {
    if (!options?.length) return [];
    return options.map(datum => {
      return {
        value: datum.id,
        label: datum.name,
        ...datum
      }
    })
  }, [options])

  const onNext = () => {
    handlePrevState()

    let newForm = { ...form }
    newForm['rent_period'].value = selectedData
    newForm['rent_period_total'].value = value

    setForm(newForm)

    setCurrentStep(STEPS.nilaiTransaksiSewa)
  }

  return (
    <WrapperAndBox>
      <div className={Styles['title']}>
        <TextMedium required>
          Durasi sewa yang disepakati
        </TextMedium>
      </div>

      <div className={Styles['input-wrapper']}>
        <TextLarge>
          Berapa lama?
        </TextLarge>
        <InputText
          disabled={disabledAll}
          onChange={(e) => setValue(e.target.value || '')}
          value={value || ''}
          placeholder="Contoh 3"
        />
        {selectedData?.label === "Bulan" && +value > 11 ? <p className='text-red-0 mt-2 text-sm'>Tidak boleh lebih dari 11 bulan</p> : "" }
      </div>
      <div className={Styles['select-wrapper']}>
        <TextLarge>
          Durasi <span className={Styles['durasi-grey']}>(tahun, bulan, minggu, hari)</span>
        </TextLarge>
        <SelectWithSearch selectedData={selectedData} disabled={disabledAll} onClick={setSelectedData} options={mappedData} placeholder="Pilih durasi" />
        <BoxFlex>
          <>
            <ButtonSebelumnya />
            {(!!selectedData.id && !!value && !(selectedData?.label === "Bulan" && +value > 11)) && <ButtonSelanjutnya onClick={onNext} />}
          </>
        </BoxFlex>
      </div>
    </WrapperAndBox>
  )
}

export default PilihDurasiSewa