import { useMemo } from 'react';
import lodash from "lodash";
import dayjs from 'dayjs';

// Hooks
import useFormatDate from 'hooks/useFormatDate';
import toRupiah from 'helpers/toRupiah';

// Components
import Tag from "./Tag";
import { ReactComponent as IconBlack } from "assets/info-black-2.svg";

// Context
import { useTTCtx } from "pages/dashboard/e-reporting/TableTransaksi/TTCtx";

const MainWidthClass = {
  tanggalDisetujui: "w-[9.7%] mr-4",
  marketing: "w-[34.74%] mr-4",
  totalNilaiTrx: "w-[13%] mr-4",
  tipe: "w-[5.21%] mr-4",
  trxId: "w-[5.21%] mr-4",
  status: "w-[16.37%]"
}

const SecondaryWidthClass = {
  idListing: "w-[9.7%] mr-4",
  gcc: "w-[13.03%] mr-4",
  statusTrx: "w-[13.03%]"
}

const TableBody = ({ thisTx, openModal }) => {
  const { mapAgents } = useTTCtx();
  const { formatDate } = useFormatDate();

  const {
    createdAt,
    agents,
    receivedCommission,
    gcc,
    type,
    tag,
    pendingCommision,
    trxId,
    listingId,
    isCanceled,
    cancelAmount
  } = useMemo(() => ({
    createdAt: formatDate(thisTx.approved_at, "D MMM, YYYY"),
    agents: (() => {
      const {
        penjualUtama,
        penjualAsisting,
        pembeliUtama,
        pembeliAsisting
      } = mapAgents(thisTx?.co_types ?? [])

      const result = [];

      !!penjualUtama && result.push(penjualUtama)
      !!penjualAsisting && result.push(penjualAsisting)
      !!pembeliUtama && result.push(pembeliUtama)
      !!pembeliAsisting && result.push(pembeliAsisting)

      return result
    })(),
    listingId: thisTx?.property?.id ?? "",
    receivedCommission: toRupiah(thisTx?.total_commission ?? ""),
    gcc: toRupiah(thisTx?.detail?.amount ?? ""),
    type: thisTx?.detail?.is_rent ? "Sewa" : "Jual",
    tag: thisTx?.tag ?? "",
    trxId: thisTx?.id ?? "",
    isCanceled: thisTx?.tgcc_status === "cancelled",
    cancelAmount: toRupiah(thisTx?.due_tgcc ?? ""),
    pendingCommision: toRupiah(Math.abs(lodash.toNumber(thisTx?.gcc) - lodash.toNumber(thisTx?.total_received_commission)))
  }), [thisTx])

  const umurTrx = useMemo(() => {
    const today = dayjs();
    const inputDate = dayjs(thisTx.approved_at);
    const difference = today.diff(inputDate, 'day');
    return difference ?? 0;

  }, [createdAt])

  return (
    <div className='px-[16px] py-[16px]'>
      <div className='px-4 pb-2 flex items-center'>
        <div className={`${MainWidthClass.tanggalDisetujui}`}>
          <p className='text-[13px] text-[#101010] font-inter'>{createdAt}</p>
        </div>
        <div className={`${MainWidthClass.marketing}`}>
          {!!agents?.length && agents.map((agent, index) =>
            <span className='text-[13px] text-[#101010] font-inter' key={`agents-${index}`}>{agent}{index < (agents.length - 1) ? ", " : ""}</span>
          )}
        </div>
        <div className={`${MainWidthClass.totalNilaiTrx}`}>
          <p className='text-[13px] text-[#101010] font-inter'>Rp{receivedCommission}</p>
        </div>
        <div className={`${MainWidthClass.tipe}`}>
          <span className='px-2 py-[0.5px] rounded-[4px] bg-[#E6E7E8] text-[13px] text-[#101010] font-inter'>{type}</span>
        </div>
        <div className={`${MainWidthClass.trxId}`}>
          <p className='text-[13px] text-[#101010] font-inter'>#{trxId}</p>
        </div>
        <div className={`${MainWidthClass.status} flex items-center justify-between`}>
          <p className={`font-inter text-[14px] leading-[20px] ${umurTrx >= 150 && "!text-red-0"}`}>{umurTrx} hari</p>
          <button onClick={openModal} className='text-[13px] font-medium flex items-center p-[6px] rounded-[6px] border border-solid border-[#D9D9D9]'>
            <IconBlack className="w-[14px] h-[14px] object-contain mr-1" />
            Detail
          </button>
        </div>
      </div>

      <div className='bg-[#FAFAFA] rounded-[2px] py-2 px-4'>
        <div className='flex items-center'>
          <div className={`${SecondaryWidthClass.idListing}`}>
            <p className='text-[11px] text-[#808285] font-inter'>ID Listing</p>
          </div>
          <div className={`${SecondaryWidthClass.gcc}`}>
            <p className='text-[11px] text-[#808285] font-inter'>GCC</p>
          </div>
          <div className={`${SecondaryWidthClass.gcc}`}>
            <p className='text-[11px] text-[#808285] font-inter'>Komisi Belum Diterima</p>
          </div>
          <div className={`${SecondaryWidthClass.statusTrx}`}>
            <p className='text-[11px] text-[#808285] font-inter'>Status Transaksi</p>
          </div>
        </div>

        <div className='w-full flex items-center'>
          <div className={`${SecondaryWidthClass.idListing}`}>
            <p className='text-[13px] text-[#101010] font-inter'>#{listingId}</p>
          </div>

          <div className={`${SecondaryWidthClass.gcc}`}>
            <p className='text-[13px] text-[#101010] font-inter font-medium'>Rp{gcc}</p>
          </div>
          <div className={`${SecondaryWidthClass.gcc}`}>
            <p className='text-[13px] text-[#FD4949] font-inter font-medium'>Rp{pendingCommision}</p>
          </div>
          <div className={`${SecondaryWidthClass.statusTrx}`}>
            <Tag tag={isCanceled ? "cancelled" : tag} />
            {isCanceled && <p className='text-xs text-gray-0 mt-[10px]'>Dibatalkan: <span className='text-red-8'>Rp{cancelAmount}</span></p>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TableBody