import { useRef, useContext, useState, useMemo } from 'react'
import lodash from "lodash";
import dayjs from 'dayjs';

// Assets
import { ReactComponent as TripleDot } from "assets/triple-dot-horizontal.svg";
import { ReactComponent as Pencil } from "assets/pencil-line-grey.svg";
import { ReactComponent as InfoGreySmall } from "assets/info-grey-small.svg";
import { ReactComponent as TrashRed } from "assets/trash-red.svg";
import { ReactComponent as CrossIcon } from "assets/cross.svg";
import { ReactComponent as IconInfo } from "assets/info-orange.svg";

// Context
import { useERMethod } from "pages/dashboard/e-reporting/ERCtx";
import { useTTCtx } from "pages/dashboard/e-reporting/TableTransaksi/TTCtx";
import { GlobalContext } from 'contexts';

// Hooks
import useOutsideAlerter from 'hooks/useOutsideAlerter';
import useFormatDate from 'hooks/useFormatDate';
import toRupiah from 'helpers/toRupiah';

// Components
import Modal from "components/organism/modal-v2";

// Aspi
import { deleteTransactionReport } from "actions"

const LabelHeader = ({ label, className, ...props }) => (<p className={`text-[11px] text-[#808285] font-inter ${className}`} {...props}>{label}</p>)

const MainWidthClass = {
  tanggalDisetujui: "w-[9.7%] mr-4",
  marketing: "w-[34.74%] mr-4",
  totalNilaiTrx: "w-[13%] mr-4",
  tipe: "w-[5.21%] mr-4",
  trxId: "w-[5.21%] mr-4",
  status: "w-[16.37%]"
}

const SecondaryWidthClass = {
  idListing: "w-[9.7%] mr-4",
  gcc: "w-[13.03%] mr-4",
  statusTrx: "w-[13.03%]"
}

const Option = ({ className, Icon, text, ...props }) => (
  <button {...props} className={`font-inter cursor-pointer first:rounded-t-md last:rounded-b-md
    flex items-center text-sm px-6 py-2.5 whitespace-nowrap
    ${className}`}>
    {!!Icon && <Icon className="w-4 h-4 object-fit mr-2" />}
    {text}
  </button>
)

const OptionsSquare = ({ thisTx }) => {
  const ref = useRef(null);
  const { userRole, setShowLoading } = useContext(GlobalContext)
  const { toUpdateListing, toApproveListing } = useERMethod()
  const { setTransactionList } = useTTCtx();

  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
  const [openDropdown, setOpenDropdown] = useState(false)

  const thisTxId = useMemo(() => thisTx?.id, [thisTx])

  const onOpenDropdown = () => setOpenDropdown(true)
  const onCloseDropdown = () => setOpenDropdown(false)
  const onOpenModalDelete = () => setIsOpenModalDelete(true)
  const onCloseModalDelete = () => setIsOpenModalDelete(false)
  const onUpdateListing = () => toUpdateListing(thisTx)
  const onApproveListing = () => toApproveListing(thisTx)

  const handleDeleteTx = async () => {
    try {
      setShowLoading(true)
      await deleteTransactionReport(thisTxId)
      setTransactionList(val => val.filter(v => v.id !== thisTxId))
      onCloseDropdown()
      onCloseModalDelete()
    } catch (error) {
      console.log(error)
    } finally {
      setShowLoading(false)
    }
  }

  useOutsideAlerter(ref, () => onCloseDropdown(false));
  return (
    <div className='relative flex items-center' ref={ref}>
      <button className='cursor-pointer' onClick={onOpenDropdown}>
        <TripleDot className='w-[34px] h-[34px] object-contain' />
      </button>

      {openDropdown && <div className='absolute bg-white rounded-md z-10 right-[2px] bottom-[2px] flex flex-col border-[1px] border-solid border-white-0 box-shadow-1'>
        {userRole.isVmb &&
          <Option
            onClick={onUpdateListing}
            Icon={Pencil}
            text="Ubah data"
          />}
        <Option
          onClick={onApproveListing}
          text={userRole.isMb ? 'Lihat laporan dan setujui' : 'Lihat laporan'}
          Icon={InfoGreySmall}
        />

        <Option
          onClick={onOpenModalDelete}
          Icon={TrashRed}
          text="Hapus laporan"
          className="text-red-0"
        />
      </div>}

      <Modal isOpen={isOpenModalDelete} onClose={onCloseModalDelete}>
        <div className='relative z-[42] w-full max-w-[424px] bg-white py-[24px] px-[32px] 
          border-[0.5px] border-solid border-white-0 box-shadow-2 rounded-[6px]'>
          <div className='flex justify-end'>
            <CrossIcon className='cursor-pointer' onClick={onCloseModalDelete} />
          </div>

          <p className='font-inter text-[18px] font-medium mb-[8px] leading-[28px]'>Yakin hapus transaksi ini?</p>
          <p className='font-inter text-[14px] text-gray-2 leading-[20px]'>
            Setelah memilih hapus, transaksi dengan ID Listing <span className='font-semibold text-black-0'>#{thisTxId}</span> akan dihapus dari daftar transaksi dan tidak dapat dikembalikan lagi.
          </p>

          <div className='flex items-center mt-[32px] pt-[16px] border-t-[1px] border-solid border-white-0'>
            <button className='font-inter w-full cursor-pointer bg-red-0 border-[1px] border-solid border-red-0 
              box-shadow-2 rounded-[6px] px-[16px] py-[10px] mr-[24px] text-[14px] leading-[20px] font-medium text-white' onClick={handleDeleteTx}>
              Ya, Hapus Transaksi
            </button>
            <button className='font-inter w-full cursor-pointer bg-white border-[1px] border-solid border-black-3 
              box-shadow-2 rounded-[6px] px-[16px] py-[10px] text-[14px] leading-[20px] font-medium text-black-0' onClick={onCloseModalDelete}>
              Batal
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const Tag = ({ tag }) => {
  if (tag.toLowerCase() === "lunas") {
    return <span className='font-inter rounded-[4px] px-[16px] py-[2px] text-white bg-green-2 text-[12px] font-medium leading-[18px]'>{tag}</span>
  }
  return <span className='font-inter rounded-[4px] px-[16px] py-[2px] text-black-0  text-[12px] bg-yellow-1 font-medium leading-[18px]'>{tag}</span>
}


const TableHeader = () => {
  return (
    <div className='px-[32px] py-[16px] flex items-center border-b-[0.5px] border-solid border-[#D8D8D9]'>
      <div className={`${MainWidthClass.tanggalDisetujui}`}>
        <LabelHeader label="Tanggal Disetujui" />
      </div>
      <div className={`${MainWidthClass.marketing}`}>
        <LabelHeader label="Marketing" />
      </div>
      <div className={`${MainWidthClass.totalNilaiTrx}`}>
        <LabelHeader label="Total Nilai Trx" />
      </div>
      <div className={`${MainWidthClass.tipe}`}>
        <LabelHeader label="Tipe" />
      </div>
      <div className={`${MainWidthClass.trxId}`}>
        <LabelHeader label="Trx ID" />
      </div>
      <div className={`${MainWidthClass.status}`}>
        <LabelHeader label="Status" />
      </div>
    </div>
  )
}

const BodyCollumn = ({ thisTx }) => {
  const { mapAgents } = useTTCtx();
  const { formatDate } = useFormatDate();

  const {
    createdAt,
    agents,
    amount,
    gcc,
    type,
    trxId,
    tag,
  } = useMemo(() => ({
    createdAt: formatDate(thisTx.created_at, "D MMM, YYYY"),
    agents: (() => {
      const {
        penjualUtama,
        penjualAsisting,
        pembeliUtama,
        pembeliAsisting
      } = mapAgents(thisTx?.co_types ?? [])

      const result = [];

      !!penjualUtama && result.push(penjualUtama)
      !!penjualAsisting && result.push(penjualAsisting)
      !!pembeliUtama && result.push(pembeliUtama)
      !!pembeliAsisting && result.push(pembeliAsisting)

      return result
    })(),
    amount: toRupiah(thisTx?.detail?.amount ?? ""),
    gcc: toRupiah(thisTx?.gcc ?? ""),
    type: thisTx?.detail?.is_rent ? "Sewa" : "Jual",
    trxId: thisTx?.id ?? "",
    tag: thisTx?.tag ?? "",
  }), [thisTx])

  return (
    <div className='py-[16px] flex justify-between items-center'>
      <div className='w-[133px] mr-[16px]'>
        <p className='font-inter text-[14px] leading-[20px]'>{createdAt}</p>
      </div>
      <div className='w-[164px] mr-[16px]'>
        {!!agents?.length && agents.map((agent, index) =>
          <p className='font-inter text-[14px] leading-[20px] overflow-hidden 
          text-ellipsis whitespace-nowrap px-2 py-1 text-black-0 bg-white-0 mb-2 rounded-md' key={`agents-${index}`}>{agent}</p>
        )}
      </div>
      <div className='w-[188px] mr-[16px]'>
        <p className='font-inter text-[14px] leading-[20px] text-gray-2'>Rp{amount}</p>
      </div>
      <div className='w-[188px] mr-[16px]'>
        <p className='font-inter text-[14px] leading-[20px] font-semibold'>Rp{gcc}</p>
      </div>
      <div className='w-[128px] mr-[16px]'>
        <span className='font-inter text-[12px] font-medium leading-[18px]
        py-[2px] px-[16px] rounded-[80px] bg-white-0'>{type}</span>
      </div>
      <div className='w-[123px] mr-[16px]'>
        <p className='font-inter text-gray-2 text-[14px] leading-[20px]'>#{trxId}</p>
      </div>
      <div className='w-[174px] mr-[16px]'>
        <p className='font-inter text-[14px] font-medium leading-[20px] text-red-0'>Perlu Persetujuan</p>
      </div>
      <div className='w-[34px] mr-[16px]'>
        <OptionsSquare thisTx={thisTx} />
      </div>
      <div className='w-[86px] mr-[16px] whitespace-nowrap'><Tag tag={tag} /></div>
    </div>
  )
}



const TableBody = ({ thisTx, openModal }) => {
  const { mapAgents } = useTTCtx();
  const { formatDate } = useFormatDate();

  const {
    createdAt,
    agents,
    receivedCommission,
    gcc,
    type,
    tag,
    pendingCommision,
    trxId,
    listingId,
    isCanceled,
    cancelAmount
  } = useMemo(() => ({
    createdAt: formatDate(thisTx.approved_at, "D MMM, YYYY"),
    agents: (() => {
      const {
        penjualUtama,
        penjualAsisting,
        pembeliUtama,
        pembeliAsisting
      } = mapAgents(thisTx?.co_types ?? [])

      const result = [];

      !!penjualUtama && result.push(penjualUtama)
      !!penjualAsisting && result.push(penjualAsisting)
      !!pembeliUtama && result.push(pembeliUtama)
      !!pembeliAsisting && result.push(pembeliAsisting)

      return result
    })(),
    listingId: thisTx?.property?.id ?? "",
    receivedCommission: toRupiah(thisTx?.detail?.amount ?? ""),
    gcc: toRupiah(thisTx?.gcc ?? ""),
    type: thisTx?.detail?.is_rent ? "Sewa" : "Jual",
    tag: thisTx?.tag ?? "",
    trxId: thisTx?.id ?? "",
    isCanceled: thisTx?.tgcc_status === "cancelled",
    cancelAmount: toRupiah(thisTx?.due_tgcc ?? ""),
    pendingCommision: toRupiah(Math.abs(lodash.toNumber(thisTx?.gcc) - lodash.toNumber(thisTx?.total_received_commission)))
  }), [thisTx])

  const umurTrx = useMemo(() => {
    const today = dayjs();
    const inputDate = dayjs(thisTx.approved_at);
    const difference = today.diff(inputDate, 'day');
    return difference ?? 0;

  }, [createdAt])

  return (
    <div className='px-[16px] py-[16px]'>
      <div className='px-4 pb-2 flex items-center'>
        <div className={`${MainWidthClass.tanggalDisetujui}`}>
          <p className='text-[13px] text-[#101010] font-inter'>{createdAt}</p>
        </div>
        <div className={`${MainWidthClass.marketing}`}>
          {!!agents?.length && agents.map((agent, index) =>
            <span className='text-[13px] text-[#101010] font-inter' key={`agents-${index}`}>{agent}{index <= (agents.length - 1) ? ", " : ""}</span>
          )}
        </div>
        <div className={`${MainWidthClass.totalNilaiTrx}`}>
          <p className='text-[13px] text-[#101010] font-inter'>Rp{receivedCommission}</p>
        </div>
        <div className={`${MainWidthClass.tipe}`}>
          <span className='px-2 py-[0.5px] rounded-[4px] bg-[#E6E7E8] text-[13px] text-[#101010] font-inter'>{type}</span>
        </div>
        <div className={`${MainWidthClass.trxId}`}>
          <p className='text-[13px] text-[#101010] font-inter'>#{trxId}</p>
        </div>
        <div className={`${MainWidthClass.status} flex items-center justify-between`}>
          <div className='flex items-center'>
            <IconInfo className="w-[16px] h-[16px] object-contain mr-1" />
            <p className={`font-inter text-[14px] leading-[20px]`}>Perlu Persetujuan</p>
          </div>

          <OptionsSquare thisTx={thisTx} />
        </div>
      </div>

      <div className='bg-[#FAFAFA] rounded-[2px] py-2 px-4'>
        <div className='flex items-center'>
          <div className={`${SecondaryWidthClass.idListing}`}>
            <p className='text-[11px] text-[#808285] font-inter'>ID Listing</p>
          </div>
          <div className={`${SecondaryWidthClass.gcc}`}>
            <p className='text-[11px] text-[#808285] font-inter'>GCC</p>
          </div>
          <div className={`${SecondaryWidthClass.statusTrx}`}>
            <p className='text-[11px] text-[#808285] font-inter'>Status Transaksi</p>
          </div>
        </div>

        <div className='w-full flex items-center'>
          <div className={`${SecondaryWidthClass.idListing}`}>
            <p className='text-[13px] text-[#101010] font-inter'>#{listingId}</p>
          </div>

          <div className={`${SecondaryWidthClass.gcc}`}>
            <p className='text-[13px] text-[#101010] font-inter font-medium'>Rp{gcc}</p>
          </div>
          <div className={`${SecondaryWidthClass.statusTrx}`}>
            <Tag tag={isCanceled ? "cancelled" : tag} />
            {isCanceled && <p className='text-xs text-gray-0 mt-[10px]'>Dibatalkan: <span className='text-red-8'>Rp{cancelAmount}</span></p>}
          </div>
        </div>
      </div>
    </div>
  )
}

const TableMenunggu = () => {
  const { transactionList } = useTTCtx();

  return (
    <div className=''>
      <div className='rounded-[4px] border-[0.5px] border-solid border-white-0 bg-white'>
        {/*Table Head*/}
        <TableHeader />

        {/*Table Body*/}
        {!!transactionList?.length && transactionList.map((datum, key) => <TableBody thisTx={datum} key={`body-column-${key}`} />)}
      </div>
    </div>
  )
}

export default TableMenunggu
