import { useMutation } from '@tanstack/react-query'
import {
  tandaiMenanggapi
} from "./api"

export const useTandaiMenanggapi = () => {
  return useMutation({
    queryKey: ['lead-listing-tandai-menanggapi'],
    mutationFn: (leadId) => tandaiMenanggapi(leadId),
  })
}
