import { useMemo } from 'react'

// Context
import { useTTCtx } from "pages/dashboard/e-reporting/TableTransaksi/TTCtx";

// Hooks
import useFormatDate from 'hooks/useFormatDate';
import toRupiah from 'helpers/toRupiah';

// Assets
import { ReactComponent as IconApproved } from "assets/approved.svg";

const LabelHeader = ({ label, className, ...props }) => (<p className={`text-[11px] text-[#808285] font-inter ${className}`} {...props}>{label}</p>)

const Tag = ({ tag }) => {
  if (tag.toLowerCase() === "cancelled") {
    return <span className='w-fit font-inter rounded-[4px] px-[8px] py-[1px] text-white bg-green-5 text-[12px] font-medium leading-[18px]'>Lunas - Komisi Dibatalkan</span>
  }
  if (tag.toLowerCase() === "lunas") {
    return <span className='w-fit font-inter rounded-[4px] px-[8px] py-[1px] text-white bg-green-2 text-[12px] font-medium leading-[18px]'>{tag}</span>
  }
  return <span className='w-fit font-inter rounded-[4px] px-[8px] py-[1px] text-black-0  text-[12px] bg-yellow-1 font-medium leading-[18px]'>{tag}</span>
}

const MainWidthClass = {
  tanggalDisetujui: "w-[9.7%] mr-4",
  marketing: "w-[34.74%] mr-4",
  totalNilaiTrx: "w-[13%] mr-4",
  tipe: "w-[5.21%] mr-4",
  trxId: "w-[5.21%] mr-4",
  status: "w-[16.37%]"
}

const SecondaryWidthClass = {
  idListing: "w-[9.7%] mr-4",
  gcc: "w-[13.03%] mr-4",
  statusTrx: "w-[13.03%]"
}

const TableHeader = () => {
  return (
    <div className='px-[32px] py-[16px] flex items-center border-b-[0.5px] border-solid border-[#D8D8D9]'>
      <div className={`${MainWidthClass.tanggalDisetujui}`}>
        <LabelHeader label="Tanggal Disetujui" />
      </div>
      <div className={`${MainWidthClass.marketing}`}>
        <LabelHeader label="Marketing" />
      </div>
      <div className={`${MainWidthClass.totalNilaiTrx}`}>
        <LabelHeader label="Total Nilai Trx" />
      </div>
      <div className={`${MainWidthClass.tipe}`}>
        <LabelHeader label="Tipe" />
      </div>
      <div className={`${MainWidthClass.trxId}`}>
        <LabelHeader label="Trx ID" />
      </div>
      <div className={`${MainWidthClass.status}`}>
        <LabelHeader label="Status" />
      </div>
    </div>
  )
}

const TableBody = ({ thisTx }) => {
  const { mapAgents } = useTTCtx();
  const { formatDate } = useFormatDate();

  const {
    createdAt,
    agents,
    amount,
    gcc,
    type,
    trxId,
    tag,
    isCanceled,
    cancelAmount,
    listingId
  } = useMemo(() => ({
    createdAt: formatDate(thisTx.approved_at, "D MMM, YYYY"),
    agents: (() => {
      const {
        penjualUtama,
        penjualAsisting,
        pembeliUtama,
        pembeliAsisting
      } = mapAgents(thisTx?.co_types ?? [])

      const result = [];

      !!penjualUtama && result.push(penjualUtama)
      !!penjualAsisting && result.push(penjualAsisting)
      !!pembeliUtama && result.push(pembeliUtama)
      !!pembeliAsisting && result.push(pembeliAsisting)

      return result
    })(),
    amount: toRupiah(thisTx?.detail?.amount ?? ""),
    gcc: toRupiah(thisTx?.gcc ?? ""),
    type: thisTx?.detail?.is_rent ? "Sewa" : "Jual",
    trxId: thisTx?.id ?? "",
    tag: thisTx?.tag ?? "",
    listingId: thisTx?.property?.id ?? "",
    isCanceled: thisTx?.tgcc_status === "cancelled",
    cancelAmount: toRupiah(thisTx?.due_tgcc ?? ""),
  }), [thisTx])

  return (
    <div className='px-[16px] py-[16px]'>
      <div className='px-4 pb-2 flex items-center'>
        <div className={`${MainWidthClass.tanggalDisetujui}`}>
          <p className='text-[13px] text-[#101010] font-inter'>{createdAt}</p>
        </div>
        <div className={`${MainWidthClass.marketing}`}>
          {!!agents?.length && agents.map((agent, index) =>
            <span className='text-[13px] text-[#101010] font-inter' key={`agents-${index}`}>{agent}{index < (agents.length - 1) ? ", " : ""}</span>
          )}
        </div>
        <div className={`${MainWidthClass.totalNilaiTrx}`}>
          <p className='text-[13px] text-[#101010] font-inter'>Rp{amount}</p>
        </div>
        <div className={`${MainWidthClass.tipe}`}>
          <span className='px-2 py-[0.5px] rounded-[4px] bg-[#E6E7E8] text-[13px] text-[#101010] font-inter'>{type}</span>
        </div>
        <div className={`${MainWidthClass.trxId}`}>
          <p className='text-[13px] text-[#101010] font-inter'>#{trxId}</p>
        </div>
        <div className={`${MainWidthClass.status}`}>
          <p className='text-[13px] text-[#101010] font-inter font-medium flex items-center'><IconApproved /> Disetujui</p>
        </div>
      </div>

      <div className='bg-[#FAFAFA] rounded-[2px] py-2 px-4'>
        <div className='flex items-center'>
          <div className={`${SecondaryWidthClass.idListing}`}>
            <p className='text-[11px] text-[#808285] font-inter'>ID Listing</p>
          </div>
          <div className={`${SecondaryWidthClass.gcc}`}>
            <p className='text-[11px] text-[#808285] font-inter font-medium'>GCC</p>
          </div>

          <div className={`${SecondaryWidthClass.statusTrx}`}>
            <p className='text-[11px] text-[#808285] font-inter'>Status Transaksi</p>
          </div>
        </div>

        <div className='w-full flex items-center'>
          <div className={`${SecondaryWidthClass.idListing}`}>
            <p className='text-[13px] text-[#101010] font-inter'>#{listingId}</p>
          </div>

          <div className={`${SecondaryWidthClass.gcc}`}>
            <p className='text-[13px] text-[#101010] font-inter'>Rp{gcc}</p>
          </div>
          <div className={`${SecondaryWidthClass.statusTrx}`}>
            <Tag tag={isCanceled ? "cancelled" : tag} />
            {isCanceled && <p className='text-xs text-gray-0 mt-[10px]'>Dibatalkan: <span className='text-red-8'>Rp{cancelAmount}</span></p>}
          </div>
        </div>
      </div>
    </div>
  )
}

const TableDisetujui = () => {
  const { transactionList } = useTTCtx();

  return (
    <div className=''>
      <div className='rounded-[4px] border-[0.5px] border-solid border-white-0 bg-white'>
        {/*Table Head*/}
        <TableHeader />

        {/*Table Body*/}
        {!!transactionList?.length && transactionList.map((datum, key) => <TableBody thisTx={datum} key={`body-column-${key}`} />)}
      </div>
    </div>
  )
}

export default TableDisetujui
