import { useState, useEffect, useMemo } from 'react'
import lodash from 'lodash';

import { FormProvider } from 'pages/dashboard/e-reporting/forms/context'
import { useERState } from "pages/dashboard/e-reporting/ERCtx";

//Components
import ModalLoader from "components/organism/ModalLoader";

const withMain = (Main) => {
  const HOCMain = (props) => {
    const { formType, updateListingData } = useERState()

    const initField = useMemo(() => ({ value: "", error: null }), [])
    const [initForm, setInitForm] = useState({
      is_co_broking: { ...initField, value: '' },
      kantor_marketing_penjual: { ...initField, value: { id: null } },
      marketing_penjual: { ...initField, value: { id: null } },
      is_co_listing_penjual: { ...initField, value: '' },
      asisten_penjual: { ...initField, value: { id: null } },
      komisi_penjual_utama: { ...initField, value: 50 },
      komisi_penjual_asisten: { ...initField, value: 50 },
      biaya_refferal_penjual: { ...initField, value: 0 },

      kantor_marketing_pembeli: { ...initField, value: { id: null } },
      marketing_pembeli: { ...initField, value: { id: null } },
      is_co_listing_pembeli: { ...initField, value: '' },
      asisten_pembeli: { ...initField, value: { id: null } },
      komisi_pembeli_utama: { ...initField, value: 50 },
      komisi_pembeli_asisten: { ...initField, value: 50 },
      biaya_refferal_pembeli: { ...initField, value: "" },

      selected_listing: { ...initField, value: { id: null } },
      provinsi_properti: { ...initField, value: { id: null } },
      kota_properti: { ...initField, value: { id: null } },
      area_properti: { ...initField, value: { id: null } },
      alamat_properti: { ...initField, value: '' },
      tanggal_transaksi: { ...initField, value: '' },
      nilai_transaksi: { ...initField, value: 0 },
      is_secondary: { ...initField, value: true },
      durasi_sewa: { ...initField, value: { id: null, value: '', label: '' } },
      periode_sewa: { ...initField, value: 0 },
      durasi_pembayaran: { ...initField, value: { id: null, value: '', label: '' } },
      periode_pembayaran: { ...initField, value: 0 },
      metode_pembayaran: { ...initField, value: { id: null, name: '' } },
      bank_yang_digunakan: { ...initField, value: { id: null, name: '' } },
      commission_rate: { ...initField, value: 0 },
      commission_amount: { ...initField, value: 0 },
      received_commission: { ...initField, value: 0 },
      plafond: { ...initField, value: 0 },
      debtor_name: { ...initField, value: "" },
      debtor_phone: { ...initField, value: "" },

      nama_pemilik_properti: { ...initField, value: '' },
      telepon_pemilik_properti: { ...initField, value: '' },
      email_pemilik_properti: { ...initField, value: '' },
      provinsi_pemilik_properti: { ...initField, value: { id: null, name: '' } },
      kota_pemilik_properti: { ...initField, value: { id: null, name: '' } },
      area_pemilik_properti: { ...initField, value: { id: null, name: '' } },
      alamat_pemilik_properti: { ...initField, value: '' },
      is_pemilik_badan_usaha: { ...initField, value: '' },

      nama_pembeli_properti: { ...initField, value: '' },
      telepon_pembeli_properti: { ...initField, value: '' },
      email_pembeli_properti: { ...initField, value: '' },
      provinsi_pembeli_properti: { ...initField, value: { id: null, name: '' } },
      kota_pembeli_properti: { ...initField, value: { id: null, name: '' } },
      area_pembeli_properti: { ...initField, value: { id: null, name: '' } },
      rent_period: { ...initField, value: { id: null, name: '' } },
      rent_period_total: { ...initField, value: 0 },

      alamat_pembeli_properti: { ...initField, value: '' },

      calculated_commission: { ...initField, value: 0 },
    })
    const [loading, setLoading] = useState(true)

    const handleRentPeriod = (period) => {
      switch (period) {
        // case "daily":
        //   return {
        //     id: 4,
        //     value: 4,
        //     name: "Hari",
        //     label: "Hari",
        //   }
        // case "weekly":
        //   return {
        //     id: 3,
        //     value: 3,
        //     name: "Minggu",
        //     label: "Minggu",
        //   }
        case "monthly":
          return {
            id: 2,
            value: 2,
            name: "Bulan",
            label: "Bulan",
          }
        case "yearly":
          return {
            id: 1,
            value: 1,
            label: "Tahun",
            name: "Tahun"
          }
        default:
          return ""
      }
    }

    useEffect(() => {
      if (!!formType && !!updateListingData) {
        const {
          agent_role_code,
          by_another_agent,
          co_types
        } = updateListingData;

        /*Mapping agent form*/
        const marketingPenjual = lodash.filter(co_types, (type) => type.kind == "co-listing")[0] ?? null;
        const marketingPembeli = lodash.filter(co_types, (type) => type.kind == "co-selling")[0] ?? null;

        //agent Penjual
        const penjualUtama = lodash.filter(marketingPenjual?.agents, (type) => type.kind == "main")[0] ?? null;
        const penjualAsisting = lodash.filter(marketingPenjual?.agents, (type) => type.kind == "assisting")[0] || null;

        //agent Pembeli
        const pembeliUtama = lodash.filter(marketingPembeli?.agents, (type) => type.kind == "main")[0] ?? null;
        const pembeliAsisting = lodash.filter(marketingPembeli?.agents, (type) => type.kind == "assisting")[0] || null;

        const isRent = updateListingData?.detail?.is_rent ? true : false;
        const isKpr = updateListingData?.detail?.payment_method == 'kpr' ? true : false;

        const newForm = {
          ...initForm,
          is_co_broking: { ...initField, value: by_another_agent ? 'ya' : 'tidak' },

          kantor_marketing_penjual: { ...initField, value: penjualUtama?.company || { id: null } },
          marketing_penjual: { ...initField, value: penjualUtama?.user || { id: null } },
          is_co_listing_penjual: { ...initField, value: !!penjualAsisting ? 'ya' : 'tidak' },
          komisi_penjual_utama: { ...initField, value: lodash.toNumber(penjualUtama?.commission_rate) },

          kantor_marketing_pembeli: { ...initField, value: pembeliUtama?.company || { id: null } },
          marketing_pembeli: { ...initField, value: pembeliUtama?.user || { id: null } },
          is_co_listing_pembeli: { ...initField, value: !!pembeliAsisting ? 'ya' : 'tidak' },
          komisi_pembeli_utama: { ...initField, value: lodash.toNumber(pembeliUtama?.commission_rate) },

          metode_pembayaran: { ...initField, value: isKpr ? { id: 2, name: 'KPR' } : { id: 1, name: 'CASH' } },
          commission_rate: { ...initField, value: lodash.toNumber(updateListingData.commission_rate) },
          commission_amount: { ...initField, value: lodash.toNumber(updateListingData.commission_amount) },
          durasi_pembayaran: { ...initField, value: { id: 2, name: 'bulan', value: 2, label: 'bulan' } },
          periode_pembayaran: { ...initField, value: lodash.toNumber(updateListingData?.rent_payment_frequency_in_month) },
          biaya_refferal_penjual: { ...initField, value: lodash.toNumber(penjualUtama?.referral_amount) },
          biaya_refferal_pembeli: { ...initField, value: lodash.toNumber(pembeliUtama?.referral_amount) },
        }

        if (updateListingData?.received_commission) {
          newForm.received_commission = { ...initField, value: lodash.toNumber(updateListingData?.received_commission) }
        }

        if (penjualAsisting) {
          newForm.asisten_penjual = { ...initField, value: penjualAsisting.user || { id: null } }
          newForm.komisi_penjual_asisten = { ...initField, value: lodash.toNumber(penjualAsisting.commission_rate) }
        }

        if (pembeliAsisting) {
          newForm.asisten_pembeli = { ...initField, value: pembeliAsisting?.user || { id: null } }
          newForm.komisi_pembeli_asisten = { ...initField, value: lodash.toNumber(pembeliAsisting?.commission_rate) }
        }

        if (updateListingData.property_location) {
          newForm.provinsi_properti = { ...initField, value: updateListingData.property_location.province || { id: null } }
          newForm.kota_properti = { ...initField, value: updateListingData.property_location.city || { id: null } }
          newForm.area_properti = { ...initField, value: updateListingData.property_location.area || { id: null } }
          newForm.alamat_properti = { ...initField, value: updateListingData.property_location.street }
        }

        if (updateListingData.transaction_date) {
          newForm.tanggal_transaksi = { ...initField, value: updateListingData.transaction_date }
        }

        if (updateListingData.detail) {
          newForm.nilai_transaksi = { ...initField, value: lodash.toNumber(updateListingData.detail.amount) }
          newForm.is_secondary = { ...initField, value: updateListingData.detail.is_secondary }
          newForm.plafond = { ...initField, value: lodash.toNumber(updateListingData.detail.plafond) }
          newForm.debtor_name = { ...initField, value: updateListingData.detail.debtor_name }
          newForm.debtor_phone = { ...initField, value: updateListingData.detail.debtor_phone }
        }

        if (updateListingData.property) {
          newForm.selected_listing = { ...initField, value: updateListingData.property }
        }

        isKpr && (newForm.bank_yang_digunakan = { ...initField, value: updateListingData.detail.kpr })
        if (isRent) {
          newForm.rent_period = { ...initField, value: handleRentPeriod(updateListingData?.detail?.rent_period) }
          newForm.rent_period_total = { ...initField, value: lodash.toNumber(updateListingData?.detail?.rent_period_total) }
        }

        if (!updateListingData.property && isRent) {
          newForm.selected_listing = { ...initField, value: { property_type: { id: 2, name: 'Sewa' } } }
        }

        if (!updateListingData.property && !isRent) {
          newForm.selected_listing = { ...initField, value: { property_type: { id: 1, name: 'Jual' } } }
        }

        if (updateListingData.property_owner) {
          newForm.nama_pemilik_properti = { ...initField, value: updateListingData.property_owner.name }
          newForm.telepon_pemilik_properti = { ...initField, value: updateListingData.property_owner.phone ? updateListingData.property_owner.phone.replace(/^0|^(\+62)/, '') : "" }
          newForm.email_pemilik_properti = { ...initField, value: updateListingData.property_owner.email }
          newForm.provinsi_pemilik_properti = { ...initField, value: updateListingData.property_owner.province }
          newForm.kota_pemilik_properti = { ...initField, value: updateListingData.property_owner.city }
          newForm.area_pemilik_properti = { ...initField, value: updateListingData.property_owner.area }
          newForm.alamat_pemilik_properti = { ...initField, value: updateListingData.property_owner.street }
          newForm.is_pemilik_badan_usaha = { ...initField, value: updateListingData.property_owner.as_business_entity ? 'ya' : 'tidak' }
        }

        if (updateListingData.buyer) {
          newForm.nama_pembeli_properti = { ...initField, value: updateListingData.buyer?.name }
          newForm.telepon_pembeli_properti = { ...initField, value: updateListingData.buyer?.phone ? updateListingData.buyer?.phone.replace(/^0|^(\+62)/, '') : "" }
          newForm.email_pembeli_properti = { ...initField, value: updateListingData.buyer?.email }
          newForm.provinsi_pembeli_properti = { ...initField, value: updateListingData.buyer?.province || { id: null } }
          newForm.kota_pembeli_properti = { ...initField, value: updateListingData.buyer?.city || { id: null } }
          newForm.area_pembeli_properti = { ...initField, value: updateListingData.buyer?.area || { id: null } }
          newForm.alamat_pembeli_properti = { ...initField, value: updateListingData.buyer?.street }
        }

        switch (agent_role_code) {
          case 'S1XX': {
            newForm.kantor_marketing_pembeli = { ...initField, value: { id: 0, name: pembeliUtama.company_name } }
            newForm.marketing_pembeli = { ...initField, value: { id: 0, full_name: pembeliUtama.agent_name, name: pembeliUtama.agent_name } }
            newForm.komisi_pembeli_utama = { ...initField, value: 100 }
            break;
          }
          case 'SXB1': {
            newForm.kantor_marketing_penjual = { ...initField, value: { id: 0, name: penjualUtama.company_name } }
            newForm.marketing_penjual = { ...initField, value: { id: 0, full_name: penjualUtama.agent_name, name: penjualUtama.agent_name } }
            newForm.komisi_penjual_utama = { ...initField, value: 100 }
            break;
          }
          default: break;
        }

        setInitForm(newForm)
      }
      setLoading(false)
    }, [])

    if (loading) return <ModalLoader open={true} />
    return <FormProvider updateListingData={updateListingData ?? null} initForm={initForm}><Main {...props} /></FormProvider>;
  };
  return HOCMain;
}

export default withMain